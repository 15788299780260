import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import LandingPage from './components/LandingPage';
import CodeChallenge from './components/CodeChallenge';
import { LoginPage } from './pages/LoginPage';
import santa from "./components/santa";
import {SignupPage} from "./pages/SignupPages";
import { PrivateRoute } from './auth/PrivateRoute';
import { UserInfoPage } from "./pages/UserInfoPage";
import CourseComponent from "./components/coursComponent";
import Santa from "./components/santa";
import Justine from "./components/Justine";
import SosoJuju from "./components/SosoJuju";
import Philippe from "./components/Philippe";


function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/santa" element={<Santa />} />
                    <Route path="/santa/justine" element={<Justine />} />
                    <Route path="/santa/phiphi" element={<Philippe />} />
                    <Route path="/santa/sosojuju" element={<SosoJuju />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/chapter/:id" element={<CodeChallenge />} />
                    <Route path="/chapter/:id/level/:levelId" element={<div style={{ display: 'flex' }}>
                        <CodeChallenge />
                        <CourseComponent />
                    </div>} />
                    <Route path="/userinfo" element={<UserInfoPage />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
