import React from 'react';
import './Justine.css';

const Justine = () => {
    return (
        <div className="justine-container">
            <h1>🎄 Joyeux Noël Justine 🎁</h1>
            <p>Voici un cadeau spécial pour toi :</p>
            <a href="https://www.youtube.com/watch?v=o-YBDTqX_ZU" target="_blank" rel="noopener noreferrer"
               className="christmas-link">
                Clique ici pour voir la surprise 🎅
            </a>
            <button
                className="real-button"
                onClick={() => window.location.href = "https://youtube.com/shorts/tfetuw6eCPA"}
            >
                C'est ici le vrai bouton
            </button>
        </div>
    );
};

export default Justine;
