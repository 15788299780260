import React from 'react';
import './SosoJuju.css';

const SosoJuju = () => {
    return (
        <div className="sosojuju-container">
            <h1>🎄 Joyeux Noël Soso Juju 🎁</h1>
            <p>Une surprise t'attend :</p>
            <a href="https://youtube.com/shorts/C034E1Dagvk" target="_blank" rel="noopener noreferrer"
               className="christmas-link">
                Clique ici pour voir la surprise 🎅
            </a>
        </div>
    );
};

export default SosoJuju;
