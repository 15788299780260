import React from 'react';
import { Link } from 'react-router-dom';
import './santa.css'

const Santa = () => {
    return (
        <div className="santa-container">
            <h1 className="santa-title">🎅 Santa's Christmas Buttons 🎄</h1>
            <div className="button-group">
                <Link to="/santa/justine">
                    <button className="christmas-button">Justine</button>
                </Link>
                <Link to="/santa/phiphi">
                    <button className="christmas-button">Philippe</button>
                </Link>
                <Link to="/santa/sosojuju">
                    <button className="christmas-button">Soso Juju</button>
                </Link>
            </div>
        </div>
    );
};

export default Santa;
