import React from 'react';
import './Philippe.css ';

const Philippe = () => {
    return (
        <div className="philippe-container">
            <h1>🎅 Noël pour Philippe 🎄</h1>
            <p>Un petit lien spécial pour toi :</p>
            <a href="https://youtu.be/Gs-2GTB0AGc" target="_blank" rel="noopener noreferrer"
               className="christmas-link">
                Clique ici pour voir la surprise 🎅
            </a>

        </div>
    );
};

export default Philippe;
